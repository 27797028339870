.navbar {
    .nav-link {
        text-transform: $navbar-link-text-transform;
        letter-spacing: $navbar-link-letter-space;
        font-size: $navbar-link-font-size;
        font-weight: $navbar-link-font-weight;
        font-family: $navbar-link-font-family;
        outline: 0;
    }
    .nav-icon-link:not(.btn) {
        white-space: nowrap;
        padding: 0;
        border: 0;
        outline: 0;
        background: transparent;
        position: relative;
        color: $navbar-light-color;
        transition: all 0.2s ease;
        &:hover:not(.disabled):not(:disabled) {
            color: $navbar-light-hover-color;
        }
        .cart-badge {
            margin-left: 0.15rem;
        }
    }
    &.navbar-expand-lg.navbar-link-white:not(.is-sticky),
    &.navbar-dark {
        .nav-item {
            .nav-icon-link {
                color: $navbar-dark-color;
                &:hover:not(.disabled):not(:disabled) {
                    color: $navbar-dark-hover-color;
                }
            }
        }
    }
}
.navbar-icons {
    .btn {
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }
}
.navbar-transparent {
    &.navbar-dark,
    &.navbar-light {
        background-color: transparent;
    }
}
.navbar-absolute-top {
    position: absolute;
    left: 0;
    top: 0;
    z-index: $zindex-fixed;
    width: 100%;
}
.navbar-transparent {
    &.navbar-dark,
    &.navbar-light {
        background-color: transparent;
    }
}

.navbar-brand {
    display: block;
    position: relative;
    padding: 0px;
    width: $spacer * 5.25;
    transition: width 0.2s ease;
    .logo-sticky,
    .logo-transparent {
        transition: transform 0.3s ease-in-out, opacity 0.25s linear;
    }
    .logo-sticky {
        position: absolute;
        left: 0;
        top: auto;
        width: 100%;
        height: auto;
        opacity: 0;
        visibility: hidden;
        transform: translateY(140%);
    }
}

@include media-breakpoint-up(lg) {
    .navbar-brand {
        width: $spacer * 7;
    }
    .navbar.navbar-expand-lg.navbar-brand-center {
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition: padding 0.25s ease-in-out;
        .navbar-brand {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.is-sticky {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .navbar.navbar-expand-lg {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    position: relative;
                    padding-left: 0;
                    padding-right: 0;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: width 0.25s ease;
                        background-color: currentColor;
                        width: 0;
                        height: 2px;
                    }
                    &:hover,
                    &.show,
                    &.active {
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .navbar-sticky.is-sticky {
        .logo-transparent {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-100%);
        }
        .logo-sticky {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
    .navbar-sticky.is-sticky {
        position: fixed;
        width: calc(100vw - 17px);
        left: 0;
        top: 0;
        z-index: $zindex-fixed;
        &.navbar-light {
            background-color: rgba($white, 0.825);
            -webkit-backdrop-filter: blur(84px);
            backdrop-filter: blur(84px);
            box-shadow: 0 0.1rem 0.2rem rgba($dark, 0.02);
        }
        &.navbar-dark {
            background-color: rgba($dark, 0.975);
            -webkit-backdrop-filter: blur(82px);
            backdrop-filter: blur(82px);
        }
    }
    .navbar-expand-lg.navbar {
        .nav-item:not(:first-child) {
            padding-left: $navbar-item-padding-left;
        }
        .nav-item {
            height: $navbar-item-height;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .navbar-expand-lg.navbar-link-white:not(.is-sticky) {
        .navbar-nav .nav-link {
            color: $navbar-dark-color;
            &:hover,
            &.show,
            &.active {
                color: $navbar-dark-hover-color;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .navbar {
        max-width: calc(100vw - 17px);
        &.fixed-top {
            width: calc(100vw - 1px);
            left: 0;
            right: 0;
        }
    }
}
.navbar-toggler {
    display: inline-flex;
    width: 3rem;
    height: 3rem;
    min-height: 3rem;
    padding: 0px;
    border: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
    background-color: $light;
    outline: 0;
    .navbar-toggler-line,
    .navbar-toggler-line-end {
        position: relative;
        background-color: $dark;
        width: 32px;
        height: 2px;
        margin-top: 2px;
        margin-bottom: 2px;
        display: block;
        transition: all 0.3s;
    }
    .navbar-toggler-line {
        left: 2px;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
    .navbar-toggler-line-end {
        left: auto;
        right: 2px;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
    }
    &[aria-expanded="true"] {
        .navbar-toggler-line {
            transform: translate3d(-2px, 2px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg)
                skew(0deg, 0deg);
            transform-style: preserve-3d;
        }
        .navbar-toggler-line-end {
            transform: translate3d(2px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg)
                skew(0deg, 0deg);
            transform-style: preserve-3d;
        }
    }
    &:focus {
        box-shadow: $box-shadow;
    }
}

//Navbar-fullscreen
.navbar-fullscreen {
    .fullscreen-toggler,
    .navbar-brand {
        z-index: 6;
    }
}
.fullscreen-toggler {
    width: 3.5rem;
    height: 3.5rem;
    background-color: currentColor;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0;
    &:focus {
        outline: 0;
    }
}
.fullscreen-toggler-lines {
    width: 32px;
    height: 2px;
    background-color: $white;
    transform-origin: center;
    transition: background-color 0.3s ease, top 0.3s ease, transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    position: relative;
    &:before,
    &:after {
        transition: background-color 0.3s ease, top 0.3s ease, transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;

        background-color: $white;
    }
    &:before {
        top: 6px;
    }
    &:after {
        bottom: 6px;
    }
}
[aria-expanded="true"] {
    > .fullscreen-toggler-lines {
        transform: rotate(45deg);
        &:after {
            transform: rotate(90deg);
            bottom: 0;
        }
        &:before {
            transform: rotate(90deg);
            top: 0;
        }
    }
}

.fullscreen-navbar {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.25s ease-in-out, visibility 0.3s ease-in-out;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}
.fullscreen-inner {
    position: relative;
    max-width: 100%;
    height: 100%;
    padding-top: 60px;
    .fullscreen-inner-wrap {
        overflow: hidden;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        .fullscreen-inner-scroll {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }
    }
}

.fullscreen-navbar {
    .fullscreen-dropdown {
        .nav-link {
            &:after {
                content: "";
                width: 6px;
                height: 6px;
                vertical-align: middle;
                border-radius: 100%;
                background-color: white;
                transition: all 0.25s ease;
                opacity: 0.25;
                display: inline-block;
                margin-left: 0.5rem;
            }
        }
    }
    .nav .nav-item {
        height: auto;
        .nav-link {
            font-size: $font-size-base * 1.75;
            letter-spacing: 0.01rem;
            text-transform: capitalize;
            font-family: $font-family-base;
            font-weight: $font-weight-bold;
            color: rgba($white, 0.85);
            &:hover,
            &.active {
                color: $white;
            }
            &[aria-expanded="true"] {
                &:after {
                    opacity: 0.85;
                }
            }
        }
        &:not(:first-child) {
            padding-left: 0;
        }
    }
}

.fullscreen-footer {
    width: 100%;
}

@include media-breakpoint-down(lg) {
    .navbar-icons {
        .nav-item.dropdown {
            position: static;
        }
        .dropdown-menu {
            width: 96%;
            left: 2%;
            margin-top: 0;
        }
    }
    .navbar-expand-lg {
        .container {
            width: 100%;
            max-width: 100%;
        }
        .navbar-collapse {
            max-height: 85vh;
            overflow-y: auto;
            margin-top: $navbar-padding-y;
            .navbar-nav {
                padding: $spacer;
            }
        }
    }
    .navbar-expand-lg.navbar-transparent.navbar-light {
        .navbar-collapse {
            background-color: $white;
            box-shadow: 0 4px 11px rgba($dark, 0.1);
        }
    }
    .navbar-expand-lg.navbar-transparent.navbar-dark {
        .navbar-collapse {
            background-color: $dark;
            box-shadow: 0 4px 11px rgba($dark, 0.125);
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar-icons {
        .dropdown.nav-item {
            position: static;
        }
    }
    .dropdown-menu.dropdown-search {
        width: 95%;
        min-width: 95%;
        right: 2.5%;
    }
}
