// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #ffffff !default;
$gray-100: #f8f8f8 !default;
$gray-200: #e7e7e7 !default;
$gray-300: #dadada !default;
$gray-400: #c7c7c7 !default;
$gray-500: #929292 !default;
$gray-600: #484848 !default;
$gray-700: #303030 !default;
$gray-800: #232323 !default;
$gray-900: #171717 !default;
$black:    #000000 !default;

$blue:   #0072ff !default;
$indigo: #192e76 !default;
$purple: #78769e !default;
$pink:   #bc2c3d !default;
$red:    #f14848 !default;
$orange: #c0a677 !default;
$yellow: #FFD780 !default;
$green:  #43a371 !default;
$teal:  #043e30 !default;
$cyan:  #5a8c91 !default;

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

$primary: $orange !default;
$secondary: $gray-700 !default;
$success: $teal !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $pink !default;
$light: $gray-100 !default;
$dark: $black !default;

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "white": $white,
    "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;
// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:  2 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              false !default;
$enable-shadows:              true !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;


$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4,
    7: $spacer * 5,
    8: $spacer * 6,
    9: $spacer * 7,
    10: $spacer * 8,
    11: $spacer * 9,
    12: $spacer * 10,
) !default;


// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 6%) !default;
$link-hover-decoration: none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-600 !default;
$body-text-align: null !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-radius: 0.25rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.3rem !default;

$rounded-pill: 50rem !default;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.08) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.05) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.08) !default;
$box-shadow-inset: inset 0 .5px 1px rgba($black, 0.03) !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:'Raleway', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-display:'Playball', cursive !default;
$font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: $font-family-sans-serif !default;
$font-family-code: var(--bs-font-monospace) !default;
// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base:.9375rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.845 !default;
$font-size-lg: $font-size-base * 1.175 !default;
$font-weight-lighter:200 !default;
$font-weight-light:300 !default;
$font-weight-normal:400 !default;
$font-weight-semibold:600 !default;
$font-weight-bold:700 !default;
$font-weight-bolder:800 !default;
$line-height-base: 1.6 !default;
$line-height-sm: 1.2 !default;
$line-height-lg: 1.8 !default;
$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.225 !default;
$h6-font-size: $font-size-base   * 1.0225  !default;
$headings-margin-bottom: $spacer / 2 !default;
$headings-line-height:$line-height-sm !default;
$headings-color: $black !default;
$sub-sup-font-size: 0.75em !default;
$text-muted: $gray-500 !default;
$hr-margin-y:                 $spacer !default;
$hr-color:                    $border-color !default;
$hr-height:                   $border-width !default;
$hr-opacity:                  1 !default;
// scss-docs-start display-headings
$display-font-sizes: (
    1: 6rem,
    2: 5rem,
    3: 4.25rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.75rem
) !default;
$display-line-height: 1.1 !default;
////TYPOHRAPHY for - [NAVBAR] [BUTTONS] [HEADINGS]
//Handle latter spacing through variables
$letter-spacing-base: .05rem !default;
$letter-spacing-headings: .15rem !default; // for Display-[1-6], h1,h2,h3
$letter-spacing-headings-sm: .075rem  !default; // for h4,h5,h6
$font-weight-base: $font-weight-normal !default;
$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;
$small-font-size: $font-size-sm !default;
//[HEADINGS]
$headings-font-family: $font-family-base !default;
$headings-font-style: null !default;
$headings-font-weight: $font-weight-bolder !default;
$headings-font-weight-sm: $font-weight-semibold !default;
//[Headings Display]
$display-font-weight: $font-weight-bolder !default;
$display-font-family: $font-family-base !default;

//[NAVBAR]
$navbar-link-text-transform: uppercase !default;
$navbar-link-letter-space:.1rem !default;
$navbar-link-font-size: $font-size-sm !default;
$navbar-link-font-weight: $font-weight-semibold !default;
$navbar-link-font-family: $font-family-base !default;

//[BUTTONS]
$btn-font-weight:             $font-weight-semibold !default;
$btn-font-family:             $font-family-base !default;
$btn-text-transform:uppercase !default;
$btn-text-space:.1rem !default;
//-----------------------------------------------------------------------



// Navbar
$navbar-padding-y: .75rem !default;
$navbar-padding-x: null !default;
$navbar-nav-link-padding-x: 0.5rem !default;
$navbar-item-padding-left: 2rem !default;
$navbar-item-height: 4.5rem !default;

$navbar-dark-color: rgba($white, 0.75) !default;
$navbar-dark-hover-color: rgba($white, 0.95) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.35) !default;
$navbar-light-color: rgba($body-color, 1) !default;
$navbar-light-hover-color: rgba($headings-color, 1) !default;
$navbar-light-active-color: rgba($primary, 1) !default;
// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 15rem !default;
$dropdown-padding-y: 1.5rem !default;
$dropdown-spacer: 0 !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-letter-spacing: $letter-spacing-base !default;
$dropdown-text-transform: capitalize !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: $border-color !default;
$dropdown-border-radius: 0rem !default;
$dropdown-border-width: 0 !default;

$dropdown-divider-bg: $border-color !default;
$dropdown-divider-margin-y: $spacer / 2 !default;
$dropdown-box-shadow: $box-shadow-lg !default;
$dropdown-link-color: rgba($dark,.65) !default;
$dropdown-link-hover-color: rgba($dark,1) !default;
$dropdown-link-hover-bg: transparent !default;
$dropdown-link-active-color: $primary !default;
$dropdown-link-active-bg: transparent !default;

$dropdown-link-disabled-color: $text-muted !default;

$dropdown-item-padding-y: $spacer / 2.75 !default;
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color: $gray-700 !default;
$dropdown-dark-color:               rgba($white,.65) !default;
$dropdown-dark-bg:                  $gray-900 !default;
$dropdown-dark-border-color:        $gray-900 !default;
$dropdown-dark-divider-bg:          $gray-700 !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       rgba($black, .5) !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      transparent !default;
$dropdown-dark-link-disabled-color: $gray-600 !default;
$dropdown-dark-header-color:        $gray-300 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .635rem !default;
$input-btn-padding-x:         .875rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-sm !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .2rem !default;
$input-btn-focus-color-opacity: .125 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow:    0 .08rem $input-btn-focus-width 0 $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .425rem !default;
$input-btn-padding-x-sm:      .545rem !default;
$input-btn-font-size-sm:      $font-size-sm * .875 !default;

$input-btn-padding-y-lg:      1.125rem !default;
$input-btn-padding-x-lg:      1.125rem !default;
$input-btn-font-size-lg:      $font-size-base !default;

$input-btn-border-width:      $border-width !default;

$input-color:                           $body-color !default;
$input-border-color:                    $border-color !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      $box-shadow-inset !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     $gray-200 !default;
$input-disabled-border-color:           null !default;

$input-color:                           $gray-700 !default;
$input-border-color:                    $gray-400 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-sm:                $border-radius-sm !default;
$input-border-radius-lg:                $border-radius-lg !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              lighten($component-active-bg, 25%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-600 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               1.75rem !default;

$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            .85rem !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            2rem !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            $input-btn-border-width !default;


$btn-box-shadow:              null !default;
$btn-focus-width:             null!default;
$btn-focus-box-shadow:        0 .25rem 1.5rem $input-btn-focus-color !default;
$btn-disabled-opacity:        .54 !default;
$btn-active-box-shadow:       null !default;

$btn-link-color:              $link-color !default;
$btn-link-hover-color:        $link-hover-color !default;
$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


//Btn sizing

$btn-font-size:               $input-btn-font-size !default;

// Badges

$badge-font-size: 0.785em !default;
$badge-font-weight: $font-weight-base !default;
$badge-color: $white !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.5em !default;
$badge-border-radius: $border-radius !default;


//Navs

$nav-tabs-border-color:             $border-color !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $border-color $border-color $nav-tabs-link-active-bg !default;


// Cards

$card-spacer-y:                     $spacer * 1.5 !default;
$card-spacer-x:                     $spacer !default;
$card-title-spacer-y:               $spacer / 2 !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 $border-color !default;


// List group

$list-group-color:                  null !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           $border-color !default;


// Tooltips

$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $gray-700 !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .95 !default;
$tooltip-padding-y:                 $spacer / 4 !default;
$tooltip-padding-x:                 $spacer / 2 !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

// Popovers

// Popovers

$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              $border-color !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                $box-shadow !default;

$popover-header-bg:                 $white !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          $spacer !default;
$popover-header-border-color:        $popover-border-color !default;
$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $spacer !default;
$popover-body-padding-x:            $spacer !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, 1) !default;


//Modal
$modal-backdrop-bg:                 $dark !default;
$modal-backdrop-opacity:            .85 !default;


// Breadcrumbs

$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              $spacer / 2 !default;
$breadcrumb-padding-x:              $spacer !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     $gray-100 !default;
$breadcrumb-divider-color:          $border-color !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;