
.btn{
  text-transform: $btn-text-transform;
  letter-spacing: $btn-text-space;
  font-weight: $btn-font-weight;
  transition:all .25s ease;
  box-shadow: none;
}
// fill buttons
@each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
    }
  }
  
  //outline buttons
  @each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }

  .btn-hover-scale{
    overflow: hidden;
    position: relative;
    &::after{
      position: absolute;
    left: 50%;
    top: 100%;
    width: 260%;
    background: rgba($dark,.075);
    padding-top: 260%;
    border-radius: 50%;
    content: "";
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: top .5s cubic-bezier(.455,.03,.515,.955),-webkit-transform .5s cubic-bezier(.455,.03,.515,.955);
    transition: top .5s cubic-bezier(.455,.03,.515,.955),-webkit-transform .5s cubic-bezier(.455,.03,.515,.955);
    transition: top .5s cubic-bezier(.455,.03,.515,.955),transform .5s cubic-bezier(.455,.03,.515,.955);
    transition: top .5s cubic-bezier(.455,.03,.515,.955),transform .5s cubic-bezier(.455,.03,.515,.955),-webkit-transform .5s cubic-bezier(.455,.03,.515,.955);
    }
    &:hover:after{
      -webkit-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
    top: -130%;
    transition-delay: .1s;
    }
    span{
      position: relative;
      z-index: 1;
    }
    &.btn-dark,&.btn-secondary,&.btn-warning{
      &:after{
        background-color: rgba($white,.075);
      }
    }
  }